// src/DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DEPOSIT_COINS, WITHDRAW_COINS } from '../common/Constants';
import '../css/ClientReportTable.css';

const ClientReportTable = ({ columns, data, tableHeader }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(1000);

    // useEffect(() => {
    //     const handleResize = () => {
    //         if (window.innerWidth <= 600) {
    //             setItemsPerPage(5);
    //         } else {
    //             setItemsPerPage(5);
    //         }
    //     };

    //     handleResize(); // Initial check
    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentData = data.slice(startIndex, startIndex + itemsPerPage);

    // Generate pagination buttons
    const pageNumbers = [];
    const visiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(1, endPage - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // let navigate = useNavigate();
    // const openLiveReport = (e, id, title) => {
    //     e.preventDefault();
    //     navigate(`/matchscreen/${sport}/${id}/${title}`);
    //     // navigate(`/livematchscreen/${sport}/${id}/${title}`);
    // };

    const [matchPlusMinus, setMatchPlusMinus] = useState(0);
    const [sessionPlusMinus, setSessionPlusMinus] = useState(0);
    const [total, setTotal] = useState(0);
    const [matchCommission, setMatchCommission] = useState(0);
    const [sessionCommission, setSessionCommission] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [net, setNet] = useState(0);
    const [agentShare, setAgentShare] = useState(0);
    const [final, setFinal] = useState(0);

    useEffect(() => {
        let matchPlusMinus = 0;
        let sessionPlusMinus = 0;
        let total = 0;
        let matchCommission = 0;
        let sessionCommission = 0;
        let totalCommission = 0;
        let net = 0;
        let agentShare = 0;
        let final = 0;

        currentData.forEach(record => {
            console.log("currentData ", record);

            matchPlusMinus = matchPlusMinus + record.matchOddsProfitLoss;
            sessionPlusMinus = sessionPlusMinus + record.sessionProfitLoss;
            total = total + record.matchProfitLoss;
            matchCommission = matchCommission + record.matchOddsCommission;
            sessionCommission = sessionCommission + record.sessionCommission;
            totalCommission = totalCommission + record.matchCommission;
            net = net + record.totalProfitLoss;
            agentShare = agentShare + record.agentShare;
            final = final + record.finalProfitLoss;
        });

        setMatchPlusMinus(matchPlusMinus);
        setSessionPlusMinus(sessionPlusMinus);
        setTotal(total);
        setMatchCommission(matchCommission);
        setSessionCommission(sessionCommission);
        setTotalCommission(totalCommission);
        setNet(net);
        setAgentShare(agentShare);
        setFinal(final);
    }, [data]);

    return (
        <div className="client-report-table-root">
            {/* <div className="client-report-table-container"> */}
            <table className="client-report-table-custom">
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th className="client-report-table-header-cell-grey" key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((row, rowIndex) => (
                        <tr key={rowIndex} className="client-report-table-row">
                            <td className="client-report-table-custom-td">
                                {row.userName}
                            </td>
                            <td className="client-report-table-custom-td">
                                {row.matchOddsProfitLoss.toFixed(1)}
                            </td>
                            <td className="client-report-table-custom-td">
                                {row.sessionProfitLoss.toFixed(1)}
                            </td>
                            <td className="client-report-table-custom-td-total">
                                {row.matchProfitLoss.toFixed(1)}
                            </td>
                            <td className="client-report-table-custom-td">
                                {row.matchOddsCommission.toFixed(1)}
                            </td>
                            <td className="client-report-table-custom-td">
                                {row.sessionCommission.toFixed(1)}
                            </td>
                            <td className="client-report-table-custom-td-total">
                                {row.matchCommission.toFixed(1)}
                            </td>
                            <td className="client-report-table-custom-td-total">
                                {row.totalProfitLoss.toFixed(1)}
                            </td>
                            <td className="client-report-table-custom-td">
                                {row.agentShare.toFixed(1)}
                            </td>
                            <td className="client-report-table-custom-td-total">
                                {row.finalProfitLoss.toFixed(2)}
                            </td>
                        </tr>
                    ))}
                    <tr className="client-report-table-row-total">
                        <td className="client-report-table-custom-td-total">
                            Total
                        </td>
                        <td className="client-report-table-custom-td">
                            {matchPlusMinus.toFixed(1)}
                        </td>
                        <td className="client-report-table-custom-td">
                            {sessionPlusMinus.toFixed(1)}
                        </td>
                        <td className="client-report-table-custom-td-total">
                            {total.toFixed(1)}
                        </td>
                        <td className="client-report-table-custom-td">
                            {matchCommission.toFixed(1)}
                        </td>
                        <td className="client-report-table-custom-td">
                            {sessionCommission.toFixed(1)}
                        </td>
                        <td className="client-report-table-custom-td-total">
                            {totalCommission.toFixed(1)}
                        </td>
                        <td className="client-report-table-custom-td-total">
                            {net.toFixed(1)}
                        </td>
                        <td className="client-report-table-custom-td">
                            {agentShare.toFixed(1)}
                        </td>
                        <td className="client-report-table-custom-td-total">
                            {final.toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* </div> */}
            {/* {currentData && <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="agent-table-pagination-prev-button"
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {startPage > 1 && <span className="pagination-ellipsis">...</span>}
                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`client-report-table-pagination-button ${currentPage === page ? 'active' : ''}`}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="agent-table-pagination-next-button"
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>} */}
        </div>
    );
};

export default ClientReportTable;
