// src/DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/CompanyLenDenTable.css';
import { DEPOSIT_CASH, WITHDRAW_CASH } from '../common/Constants';
import TableHeader from '../TableHeader';
import Clock_Icon from '../../../assets/clock_icon.svg';

const CompanyLenDenTable = ({ columns, data, tableHeader }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                setItemsPerPage(5);
            } else {
                setItemsPerPage(5);
            }
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const totalPages = Math.ceil(data && data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentData = data && data.slice(startIndex, startIndex + itemsPerPage);

    // Generate pagination buttons
    const pageNumbers = [];
    const visiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(1, endPage - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="company-len-den-table-root">
            <div className="company-len-den-table-container">
                <table className="company-len-den-table">
                    <thead>
                        {/* <tr> */}
                        {columns.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                        {/* </tr> */}
                    </thead>
                    <tbody>
                        {currentData && currentData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>
                                    <img src={Clock_Icon} className="clock-icon"></img>{row.date}
                                </td>
                                <td>
                                    <Link className="td-market-sport-link" style={{ textDecoration: "none" }}>
                                        {row.entry}
                                    </Link>
                                </td>
                                <td>
                                    {row.debit === 0 && "-"}
                                    {row.debit != 0 && row.debit.toFixed(2)}
                                </td>
                                <td>
                                    {row.credit != 0 && row.credit.toFixed(2)}
                                    {row.credit === 0 && "-"}
                                </td>
                                <td>
                                    {row.balance.toFixed(2)}
                                </td>
                                <td>
                                    {row.note}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="pagination-button"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {startPage > 1 && <span className="pagination-ellipsis">...</span>}
        {pageNumbers.map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="pagination-button"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div> */}
            </div>
        </div>
    );
};

export default CompanyLenDenTable;
