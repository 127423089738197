import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import '../css/BetSlipTable.css';
import StyledTablePagination from './BetSlipStyledTablePagination';
import Clock_Icon from '../../../assets/clock_icon.svg';

// Styled TableContainer with full width and borders
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    width: '98.6%', // Ensure full width
    //maxHeight: 'calc(100vh - 200px)', // Adjust height for responsiveness
    overflowY: 'auto', // Enable vertical scrolling
    overflowX: 'auto', // Enable horizontal scrolling
    position: 'relative', // Position relative for pagination alignment
    marginBottom: "50px",
    bornder: 'none',
    // Hide scrollbars
    '&::-webkit-scrollbar': {
        width: '0px', // Hide scrollbar
        height: '0px', // Hide horizontal scrollbar
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent', // Hide scrollbar thumb
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent', // Hide scrollbar track
    },
    '-ms-overflow-style': 'none', // Hide scrollbar in IE and Edge
    'scrollbar-width': 'none', // Hide scrollbar in Firefox
    background: 'white',
    paddingTop: '10px',
}));

// Styled Table with borders
const StyledTable = styled(Table)(({ theme }) => ({
    width: '99%', // Ensure full width
    height: 'auto',
    borderCollapse: 'collapse', // Ensure borders collapse
    '& thead th': {
        backgroundColor: '#f5f5f6', //theme.palette.grey[100], // Header background color (darker grey)
        color: "#676a6c", //theme.palette.text.primary, // Header text color
        position: 'sticky',
        top: 0,
        borderTop: `1px solid ${theme.palette.divider}`, // Left border for headers
        borderBottom: `1px solid ${theme.palette.divider}`, // Bottom border for headers
        borderLeft: `1px solid ${theme.palette.divider}`, // Left border for headers
        borderRight: `1px solid ${theme.palette.divider}`, // Right border for headers
        textAlign: 'center', // Center align text in headers
        fontSize: '13px',
        fontWeight: '700',
        textAlign: 'left',
        whiteSpace: "nowrap",
        lineHeight: '19px',
    },
    '& tbody tr:nth-of-type(odd)': {
        backgroundColor: '#f9f9f9', // Background color for even rows
        lineHeight: '19px',
        fontWeight: '400',
    },
    '& tbody tr:nth-of-type(even)': {
        backgroundColor: "white", // Background color for odd rows
        lineHeight: '19px',
        fontWeight: '400',
    },
    '& tbody td': {
        borderBottom: `0.5px solid ${theme.palette.divider}`, // Bottom border for cells
        borderLeft: `0.5px solid ${theme.palette.divider}`, // Left border for cells
        borderRight: `0.5px solid ${theme.palette.divider}`, // Right border for cells
        textAlign: 'center', // Center align text in cells
        fontSize: '13px',
        color: '#676a6c',
        textAlign: 'left',
        fontWeight: "400",
        verticalAlign: 'top',
    },
    fontSmooth: 'always',
}));

// Styled TablePagination to match the theme and align left
// const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
//   '& .MuiTablePagination-toolbar': {
//     width: '100%',
//     display: 'flex',
//     justifyContent: 'flex-start',
//     padding: '8px 16px', // Adjust padding
//     backgroundColor: theme.palette.background.paper, // Ensure background color
//     textAlign: 'left',
//     paddingRight: 'auto',
//   },
//   // Ensure pagination is visible on horizontal scroll
//   position: 'relative',
//   bottom: 0,
// }));

// Sample data generation
const generateData = (rows = 100, cols = 14) => {
    const data = [];
    for (let i = 0; i < rows; i++) {
        const row = [];
        for (let j = 0; j < cols; j++) {
            if (j == 0) {
                row.push("Sun Sep 01 15:29:22 IST 2024");
            } else if (j == 1) {
                row.push("Match Winner");
            } else if (j == 2) {
                row.push("0.58");
            } else if (j == 3) {
                row.push("1000");
            } else if (j == 4) {
                row.push("KHAI");
            } else if (j == 5) {
                row.push("Meerut Mavericks");
            } else if (j == 6) {
                row.push("SP191857");
            } else if (j == 7) {
                row.push("1000.0");
            } else if (j == 8) {
                row.push("-580.0");
            } else if (j == 9) {
                row.push("12.0%");
            } else if (j == 10) {
                row.push("120.0");
            } else if (j == 11) {
                row.push("-69.6");
            } else if (j == 12) {
                row.push("Settle");
            } else if (j == 13) {
                row.push("120.0");
            }
            // row.push(`Cell ${i + 1}-${j + 1}`);
        }
        data.push(row);
    }
    return data;
};

// BigTable Component
const BetSlipStyledPaginationTableSession = ({ open, isSmallScreen, betSlipEntries }) => {
    const theme = useTheme();
    //const data = generateData();
    const data = betSlipEntries;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [betAmountTotal, setBetAmountTotal] = useState(0);
    const [candidatePositionOneTotal, setCandidatePositionOneTotal] = useState(0);
    const [candidatePositionTwoTotal, setCandidatePositionTwoTotal] = useState(0);
    const [myCandidatePositionOneTotal, setMyCandidatePositionOneTotal] = useState(0);
    const [myCandidatePositionTwoTotal, setMyCandidatePositionTwoTotal] = useState(0);
    const [settlementPlusMinusTotal, setSettlementPlusMinusTotal] = useState(0);

    useEffect(() => {
        const slicedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        let amountTotal = 0;
        let candidatePositionTotal1 = 0;
        let candidatePositionTotal2 = 0;
        let myCandidatePositionTotal1 = 0;
        let myCandidatePositionTotal2 = 0;
        let settlementTotal = 0;

        console.log("slicedData ", slicedData);

        slicedData.forEach(record => {
            console.log("slicedData ", record);
            
            amountTotal = amountTotal + record.amount;

            candidatePositionTotal1 = candidatePositionTotal1 + record.candidatePositions["no"];
            candidatePositionTotal2 = candidatePositionTotal2 + record.candidatePositions["yes"];

            myCandidatePositionTotal1 = myCandidatePositionTotal1 + record.myCandidatePositions["no"];
            myCandidatePositionTotal2 = myCandidatePositionTotal2 + record.myCandidatePositions["yes"];

            settlementTotal = settlementTotal + record.finalBetPosition;
        });

        setBetAmountTotal(amountTotal);
        console.log("slicedData positions 1", candidatePositionTotal1, candidatePositionTotal2);
        setCandidatePositionOneTotal(candidatePositionTotal1);
        setCandidatePositionTwoTotal(candidatePositionTotal2);
        console.log("slicedData positions 2", myCandidatePositionTotal1, myCandidatePositionTotal2);
        setMyCandidatePositionOneTotal(myCandidatePositionTotal1);
        setMyCandidatePositionTwoTotal(myCandidatePositionTotal2);
        console.log("slicedData positions 3", settlementTotal);
        setSettlementPlusMinusTotal(settlementTotal);
    }, [betSlipEntries]);

    const customTable = {
        thTableCell: {
            padding: '5px',
        },
        tdTableCell: {
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '5px',
            paddingBottom: '5px',
        },
        tdTablePhone: {
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '8px',
            paddingBottom: '8px',
            whiteSpace: 'nowrap',
        },
        tdTableCellTotal: {
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '5px',
            paddingBottom: '5px',
            background: 'white',
        },
        tdTablePhoneTotal: {
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '8px',
            paddingBottom: '8px',
            whiteSpace: 'nowrap',
            background: 'white',
        },
        listItemIcon: {
            color: 'lightgrey',
            minWidth: 0,
            justifyContent: 'center',
            paddingLeft: 0.5,
            fontSize: 20,
        },
        listItemText: {
            color: 'lightgrey',
            marginLeft: 3,
        },
        sublListItem: {
            color: 'lightgrey'
        },
        sublListItem: {
            color: 'lightgrey'
        },
        drawer: {
            flexShrink: 0,
        },
    };

    return (
        <div>
            <StyledTableContainer style={{ marginLeft: "5px" }}>
                <div className={open ? "bet-slip-table-parent-open" : "bet-slip-table-parent-close"} >
                    <StyledTable>
                        <TableHead>
                            {/* Colspan Headers */}
                            <TableRow>
                                <TableCell colSpan={10} sx={customTable.thTableCell}>
                                    Bet
                                </TableCell>
                                <TableCell colSpan={4} sx={customTable.thTableCell}>
                                    My Share / Position
                                </TableCell>
                                <TableCell colSpan={1} sx={customTable.thTableCell}>
                                    Settlement
                                </TableCell>
                            </TableRow>
                            {/* Main Headers */}
                            <TableRow>
                                <TableCell sx={customTable.thTableCell}>Bet ID</TableCell>
                                <TableCell sx={customTable.thTableCell}>DATE</TableCell>
                                <TableCell sx={customTable.thTableCell}>User</TableCell>
                                <TableCell sx={customTable.thTableCell}>Session Title</TableCell>
                                <TableCell sx={customTable.thTableCell}>RATE</TableCell>
                                <TableCell sx={customTable.thTableCell}>RUNS</TableCell>
                                <TableCell sx={customTable.thTableCell}>AMOUNT</TableCell>
                                <TableCell sx={customTable.thTableCell}>MODE</TableCell>
                                <TableCell sx={customTable.thTableCell}>No</TableCell>
                                <TableCell sx={customTable.thTableCell}>Yes</TableCell>
                                <TableCell sx={customTable.thTableCell}>My Share</TableCell>
                                <TableCell sx={customTable.thTableCell}>No</TableCell>
                                <TableCell sx={customTable.thTableCell}>Yes</TableCell>
                                <TableCell sx={customTable.thTableCell}>Status</TableCell>
                                <TableCell sx={customTable.thTableCell}>Plus / Minus</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell key={rowIndex} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}>{1000000 + rowIndex}</TableCell>
                                    <TableCell key={rowIndex + 1} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}><img src={Clock_Icon} className="clock-icon" alt="Clock Icon" />{row.date}</TableCell>
                                    <TableCell key={rowIndex + 7} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}>{row.userId}</TableCell>
                                    <TableCell key={rowIndex + 5} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}>{row.sessionTitle}</TableCell>
                                    <TableCell key={rowIndex + 3} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}>{row.rate}</TableCell>
                                    <TableCell key={rowIndex + 3} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}>{row.runs}</TableCell>
                                    <TableCell key={rowIndex + 4} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}>{row.amount}</TableCell>
                                    <TableCell key={rowIndex + 2} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}>{row.betMode.toUpperCase()}</TableCell>
                                    <TableCell key={rowIndex + 8} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell} style={{ fontWeight: "bold" }}>{row.candidatePositions["no"].toFixed(1)}</TableCell>
                                    <TableCell key={rowIndex + 9} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell} style={{ fontWeight: "bold" }}>{row.candidatePositions["yes"].toFixed(1)}</TableCell>
                                    <TableCell key={rowIndex + 10} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}>{row.myShare.toFixed(1)}%</TableCell>
                                    <TableCell key={rowIndex + 11} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell} style={{ fontWeight: "bold" }}>{row.myCandidatePositions["no"].toFixed(1)}</TableCell>
                                    <TableCell key={rowIndex + 12} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell} style={{ fontWeight: "bold" }}>{row.myCandidatePositions["yes"].toFixed(1)}</TableCell>
                                    {row.betStatus === "settled" && <TableCell key={rowIndex + 13} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}><label className={isSmallScreen ? "bet-slip-table-cell-bet-status-settled" : "bet-slip-table-cell-bet-status-settled"}>{row.betStatus[0].toUpperCase() + row.betStatus.slice(1)}</label></TableCell>}
                                    {row.betStatus === "unsettled" && <TableCell key={rowIndex + 13} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}><label className={isSmallScreen ? "bet-slip-table-cell-bet-status-unsettled" : "bet-slip-table-cell-bet-status-unsettled"}>{row.betStatus[0].toUpperCase() + row.betStatus.slice(1)}</label></TableCell>}
                                    <TableCell key={rowIndex + 14} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell} style={{ fontWeight: "bold" }}>{row.finalBetPosition.toFixed(1)}</TableCell>
                                    {/* {row.map((cell, cellIndex) => (
                                        <TableCell key={cellIndex} sx={isSmallScreen ? customTable.tdTablePhone : customTable.tdTableCell}>{cellIndex === 0 && <img src={Clock_Icon} className="clock-icon" alt="Clock Icon" />}{cell}</TableCell>
                                    ))} */}
                                </TableRow>
                            ))}
                            <TableRow>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal}></TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal}></TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal} style={{ fontWeight: "bold" }}>Total</TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal}></TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal}></TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal}></TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal} style={{ fontWeight: "bold" }}>{betAmountTotal.toFixed(2)}</TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal}></TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal} style={{ fontWeight: "bold" }}>{candidatePositionOneTotal.toFixed(2)}</TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal} style={{ fontWeight: "bold" }}>{candidatePositionTwoTotal.toFixed(2)}</TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal}></TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal} style={{ fontWeight: "bold" }}>{myCandidatePositionOneTotal.toFixed(2)}</TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal} style={{ fontWeight: "bold" }}>{myCandidatePositionTwoTotal.toFixed(2)}</TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal}></TableCell>
                                    <TableCell sx={isSmallScreen ? customTable.tdTablePhoneTotal : customTable.tdTableCellTotal} style={{ fontWeight: "bold" }}>{settlementPlusMinusTotal.toFixed(2)}</TableCell>
                                </TableRow>
                        </TableBody>
                    </StyledTable>
                    <StyledTablePagination
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </StyledTableContainer>
            {/* <StyledTablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
        </div >
    );
};

export default BetSlipStyledPaginationTableSession;
