// src/DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DEPOSIT_COINS, WITHDRAW_COINS } from '../common/Constants';
import '../css/SessionEarningReportTable.css';

const SessionEarningReportTable = ({ columns, data, tableHeader }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(1000);

    // useEffect(() => {
    //     const handleResize = () => {
    //         if (window.innerWidth <= 600) {
    //             setItemsPerPage(5);
    //         } else {
    //             setItemsPerPage(5);
    //         }
    //     };

    //     handleResize(); // Initial check
    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentData = data.slice(startIndex, startIndex + itemsPerPage);

    // Generate pagination buttons
    const pageNumbers = [];
    const visiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(1, endPage - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // let navigate = useNavigate();
    // const openLiveReport = (e, id, title) => {
    //     e.preventDefault();
    //     navigate(`/matchscreen/${sport}/${id}/${title}`);
    //     // navigate(`/livematchscreen/${sport}/${id}/${title}`);
    // };

    const [session, setSession] = useState(0);
    const [commissionSession, setCommissionSession] = useState(0);
    const [total, setTotal] = useState(0);
    const [shrAmt, setShrAmt] = useState(0);
    const [final, setFinal] = useState(0);

    useEffect(() => {
        let session = 0;
        let commissionSession = 0;
        let total = 0;
        let shrAmt = 0;
        let final = 0;

        currentData.forEach(record => {
            console.log("currentData ", record);

            session = session + record.sessionProfitLoss;
            commissionSession = commissionSession + record.sessionCommission;
            total = total + record.totalProfitLoss;
            shrAmt = shrAmt + record.agentShare;
            final = final + record.parentShare;
        });

        setSession(session);
        setCommissionSession(commissionSession);
        setTotal(total);
        setShrAmt(shrAmt);
        setFinal(final);
    }, [data]);

    return (
        <div className="session-earning-report-table-root">
            {/* <div className="session-earning-report-table-container"> */}
            <table className="session-earning-report-table-custom">
                <thead>
                    <tr>
                        <th className="session-earning-report-table-header-cell-grey" colSpan={4} style={{ textAlign: 'center' }}>
                            SYSTEM
                        </th>
                        <th className="session-earning-report-table-header-cell-grey" style={{ textAlign: 'center' }}>
                            My Share
                        </th>
                        <th className="session-earning-report-table-header-cell-grey" style={{ textAlign: 'center' }}>
                            Company Share
                        </th>
                    </tr>
                    <tr>
                        {columns.map((column, index) => (
                            <th className="session-earning-report-table-header-cell-grey" key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((row, rowIndex) => (
                        <tr key={rowIndex} className="session-earning-report-table-row">
                            <td className="session-earning-report-table-custom-td">
                                {row.userName}
                            </td>
                            <td className="session-earning-report-table-custom-td">
                                {row.sessionProfitLoss.toFixed(2)}
                            </td>
                            <td className="session-earning-report-table-custom-td">
                                {row.sessionCommission.toFixed(2)}
                            </td>
                            <td className="session-earning-report-table-custom-td">
                                {row.totalProfitLoss.toFixed(2)}
                            </td>
                            <td className="session-earning-report-table-custom-td">
                                {row.agentShare.toFixed(2)}
                            </td>
                            <td className="session-earning-report-table-custom-td">
                                {row.parentShare.toFixed(2)}
                            </td>
                        </tr>
                    ))}
                    <tr className="session-earning-report-table-row-total">
                        <td className="session-earning-report-table-custom-td-total">
                            Total
                        </td>
                        <td className="session-earning-report-table-custom-td-total">
                            {session.toFixed(2)}
                        </td>
                        <td className="session-earning-report-table-custom-td-total">
                            {commissionSession.toFixed(2)}
                        </td>
                        <td className="session-earning-report-table-custom-td-total">
                            {total.toFixed(1)}
                        </td>
                        <td className="session-earning-report-table-custom-td-total">
                            {shrAmt.toFixed(2)}
                        </td>
                        <td className="session-earning-report-table-custom-td-total">
                            {final.toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* </div> */}
            {/* {currentData && <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="agent-table-pagination-prev-button"
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {startPage > 1 && <span className="pagination-ellipsis">...</span>}
                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`session-earning-report-table-pagination-button ${currentPage === page ? 'active' : ''}`}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="agent-table-pagination-next-button"
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>} */}
        </div>
    );
};

export default SessionEarningReportTable;
