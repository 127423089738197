// import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useState, useEffect, useRef } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Login from "./components/cric247/Login";
// import Register from "./components/Register";
// import Register from "./components/parvati/Register";
import CricRegister from "./components/cric247/Register";
import Edit from "./components/parvati/Edit";
import ChangeUserPassword from "./components/parvati/ChangeUserPassword";
// import ChangeMyPassword from "./components/parvati/ChangeMyPassword";
import CricChangeMyPassword from "./components/cric247/CricChangeMyPassword";
import Deposit from "./components/parvati/Deposit";
import Withdraw from "./components/parvati/Withdraw";
import Home from "./components/parvati/Home";
import CricHome from "./components/cric247/Home";
// import Statement from "./components/parvati/Statement";
import CricStatement from "./components/cric247/Statement";
import Ledgers from "./components/cric247/Ledgers";
import MatchesLiveTable from "./components/parvati/MatchesLiveTable";
import MatchesUpcomingTable from "./components/parvati/MatchesUpcomingTable";
import MatchesPastTable from "./components/parvati/MatchesPastTable";
import RegisterGames from "./components/parvati/RegisterGames";
import GameStart from "./components/parvati/GameStart";
import GameScreen from "./components/parvati/GameScreen";
import GameScreen2 from "./components/parvati/GameScreen2";
import MatchComplete from "./components/parvati/MatchComplete";
import MUIPaginationBetHistory from "./components/parvati/MUIPaginationBetHistory";
import MUIPaginationMasterDownline from "./components/parvati/MUIPaginationMasterDownline";
import MUIPMasterBook from "./components/parvati/MUIPMasterBook";
import MUIPMasterBook1 from "./components/parvati/MUIPMasterBook1";
import MUIPMasterBook2 from "./components/parvati/MUIPMasterBook2";
import MUIPMasterBook3 from "./components/parvati/MUIPMasterBook3";
import MUIPMasterBook4 from "./components/parvati/MUIPMasterBook4";
import MUIPImmediateChildren from "./components/parvati/MUIPImmediateChildren";
import MUIPImmediateChildren1 from "./components/parvati/MUIPImmediateChildren1";
import MUIPImmediateChildren2 from "./components/parvati/MUIPImmediateChildren2";
import MUIPImmediateChildren3 from "./components/parvati/MUIPImmediateChildren3";
import MUIPUpcomingMatches from "./components/parvati/MUIPUpcomingMatches";
import MUIPPastMatches from "./components/parvati/MUIPPastMatches";
import MUIPPastEventExposure from "./components/parvati/MUIPPastEventExposure";
import MUIPPastEventExposure1 from "./components/parvati/MUIPPastEventExposure1";
import MUIPPastEventExposure2 from "./components/parvati/MUIPPastEventExposure2";
import MUIPPastEventExposure3 from "./components/parvati/MUIPPastEventExposure3";
import MUIPPastEventExposure4 from "./components/parvati/MUIPPastEventExposure4";
import SupportedSports from "./components/parvati/SupportedSports";
import MatchScreenCricket from './components/parvati/MatchScreenCricket';
import LiveMatchesTable from './components/parvati/LiveMatchesTable';
import LiveMatches from "./components/cric247/LiveMatches";
// import Home from "./components/Home";
import Profile from "./components/Profile";
// import ReportAdmin from "./components/ReportAdmin";
// import ChangePassword from "./components/ChangePassword";
import CricChangePassword from "./components/cric247/ChangePassword";
import { httpHelpers } from "./services/httpHelpers";
import TokenService from "./services/token-service";
// import NavbarNew  from "./components/NavbarNew";
import MarqeeNavPath from "./components/cric247/MarqeeNavPath";
import CricFooter from "./components/cric247/Footer";
import { sidebarDataAdmin } from "./components/cric247/navbar/SidebarDataAdmin.jsx";
import { sidebarDataAgent } from "./components/cric247/navbar/SidebarDataAgent.jsx";
import { sidebarDataSC } from "./components/cric247/navbar/SidebarDataSC.jsx";
import { sidebarDataSST } from "./components/cric247/navbar/SidebarDataSST.jsx";
import { sidebarDataStockist } from "./components/cric247/navbar/SidebarDataStockist.jsx";
import { sidebarDataSuperAdmin } from "./components/cric247/navbar/SidebarDataSuperAdmin.jsx";
import { sidebarDataSM } from './components/cric247/navbar/SidebarDataSM.jsx';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import ExpandMore icon
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { FaChevronDown, FaChevronUp, FaRupeeSign, FaSignOutAlt } from 'react-icons/fa';
import Button from '@mui/material/Button';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MyDownline from './components/cric247/MyDownline.js';
import PaginatedUserTable from './components/cric247/MyDownlinePagination.js';
import TableCustomized from './components/cric247/TableCustomized.js';
import MyDownlineTable from './components/cric247/tables/MyDownlineTable.js';
import MyDownlines from './components/cric247/MyDownlines.js';
import ImmediateChildrens from './components/parvati/ImmediateChildrens.js';
import AgentMatchDashboard from './components/cric247/AgentMatchDashboard.js';
// import LiveMatchesScreen from './components/cric247/LiveMatchesScreen.js';
// import MyPage from './components/cric247/livereport/MyPage.js';
import LiveMatchScreen from './components/cric247/livereport/LiveMatchScreen.js';
import AgentDashboard from './components/cric247/AgentDashboard.js';
import ReceiveCash from './components/cric247/ReceiveCash.js';
import PayCash from './components/cric247/PayCash.js';
import Update from './components/cric247/Update.js';
import CollectionReport from './components/cric247/CollectionReport.js';
import EntityLedger from './components/cric247/EntityLedger.js';
import AdminSettings from './components/cric247/AdminSettings.js';
import UpdateGlobalProperties from './components/cric247/UpdateGlobalProperties.js';
import CommisionAndLimits from './components/cric247/CommisionAndLimits.js';
import MyClients from './components/cric247/MyClients.js';
import Breadcrumbs from './components/cric247/Breadcrumbs.js';
import Marqee from './components/cric247/Marqee.js';
import CricChangeUserPassword from './components/cric247/CricChangeUserPassword.js';
import EntityStatement from './components/cric247/EntityStatement.js';
import DirectAgents from './components/cric247/DirectAgents.js';
import BlockMarket from './components/cric247/BlockMarket.js';
import BetSlipMatch from './components/cric247/betslip/BetSlipMatch.js';
import App_Icon from './assets/Nice-247.png';
import SearchMole from './components/cric247/SearchMole.js';
import BetSlipSession from './components/cric247/betslip/BetSlipSession.js';
import CompanyLenDen from './components/cric247/CompanyLenDen.js';
import CollectionReportMatch from './components/cric247/CollectionReportMatch.js';
import ProfitAndLoss from './components/cric247/ProfitAndLoss.js';
import ClientReport from './components/cric247/ClientReport.js';
import SessionEarningReport from './components/cric247/SessionEarningReport.js';
import CompanyReport from './components/cric247/CompanyReport.js';

const isSmallScreen = window.matchMedia("(max-width: 700px)").matches ? true : false;
const drawerWidth = isSmallScreen ? 240 : 240;

const openedMixin = (theme) => ({
    width: (isSmallScreen ? 240 : 240),
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: `calc(${theme.spacing(7)} + 1px)`,
    width: (isSmallScreen ? 0 : 64),
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        fontSize: 12,
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

// const SubMenuContainer = styled('div')({
//     position: 'absolute',
//     width: '200px',
//     marginLeft: (isSmallScreen ? -177 : -1),
//     transition: 'transform 0ms ease-in-out',
//     transform: 'translateX(100%)',
//     zIndex: 1201,
//     backgroundColor: 'white',
//     boxShadow: '0 3px 5px rgba(0, 0, 0, 0.3)',
//     marginTop: '15px',
// });

// const SubMenuList = styled(List)({
//     padding: 0,
//     margin: 0,
// });

const customDrawerStyles = {
    listItem: {
        color: '#f3f3f4'
    },
    listItemIcon: {
        color: '#f3f3f4',
        minWidth: 0,
        justifyContent: 'center',
        paddingLeft: 0.5,
        fontSize: 20,
    },
    listItemIconRed: {
        color: 'red',
        minWidth: 0,
        justifyContent: 'center',
        paddingLeft: 0.5,
        fontSize: 20,
    },
    listItemText: {
        color: '#f3f3f4',
        marginLeft: 3,
    },
    sublListItem: {
        color: '#f3f3f4'
    },
    sublListItem: {
        color: '#f3f3f4'
    },
    drawer: {
        flexShrink: 0,
    },
};

export default function MiniNavBar({ role, logout, user, visitedPaths, supportedSports }) {

    console.log("MiniNavBar user", user);
    const [activeUser, setActiveUser] = useState(user && user.accountStatus === "ACTIVE");
    console.log("MiniNavBar activeUser", activeUser);
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedSubIndex, setSelectedSubIndex] = useState(null);
    const [subMenuItem, setSubMenuItem] = useState(null);
    const [subMenuPosition, setSubMenuPosition] = useState({ top: 0, left: 50 });
    const listItemRefs = useRef([]);

    const handleClickOutside = (event) => {
        setActiveSubMenu(null);
        handleSubMenuToggle(null);
    };

    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    // useEffect(() => {
    //     if (activeSubMenu !== null && listItemRefs.current[activeSubMenu]) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //         document.removeEventListener('touchstart', handleClickOutside);
    //         window.addEventListener('scroll', handleClickOutside);
    //         const rect = listItemRefs.current[activeSubMenu].getBoundingClientRect();
    //         setSubMenuPosition({
    //             top: rect.top + window.scrollY + rect.height - 64,
    //             left: open ? 40 : -135,
    //         });
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //         document.removeEventListener('touchstart', handleClickOutside);
    //         window.removeEventListener('scroll', handleClickOutside);
    //     }
    // }, [open, theme, activeSubMenu]);

    const handleDrawerOpen = () => {
        setOpen(true);
        setActiveSubMenu(null);
        handleSubMenuToggle(null);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setActiveSubMenu(null);
        handleSubMenuToggle(null);
    };

    const handleMenuToggle = (index, item) => {
        console.log("handleSubMenu menu", index, item);
        if (item) {
            if (!open)
                handleDrawerOpen();
            setSelectedSubIndex(null);
            setSubMenuItem((prev) => (prev === item ? null : item));
            setActiveSubMenu((prev) => (prev === index ? null : index));
            setSelectedIndex((prev) => (prev === index ? null : index));
        } else {
            handleSubMenuToggle(null);
            setSelectedIndex((prev) => (prev === index ? null : index));
            if (index != null)
                handleDrawerClose();
        }
    };

    const handleSubMenuToggle = (index, item) => {
        console.log("handleSubMenu", index, item);
        setSubMenuItem((prev) => (prev === item ? null : item));
        setActiveSubMenu((prev) => (prev === index ? null : index));

        if (index != null) {
            setSelectedSubIndex((prev) => (prev === index ? null : index));
            if (index != null)
                handleDrawerClose();
        }
    };

    const [balance, setBalance] = useState();
    let url = "beta/getBalance";
    const api = httpHelpers()

    let sideBarData;
    let myRole;
    let mRole = role;
    let eType = "";
    const [dropDown, setDropDown] = useState();

    if (mRole === null) {
        mRole = TokenService.getRole();
    }

    if (mRole.includes("superadmin")) {
        if (activeUser)
            sideBarData = sidebarDataSuperAdmin;
        myRole = "Super Admin";
        eType = "SA";
    } else if (mRole.includes("admin")) {
        if (activeUser)
            sideBarData = sidebarDataAdmin;
        myRole = "Admin";
        eType = "Admin";
    } else if (mRole.includes("agent")) {
        if (activeUser)
            sideBarData = sidebarDataAgent;
        myRole = "Agent";
        eType = "Agent";
    } else if (mRole.includes("submaster")) {
        if (activeUser)
            sideBarData = sidebarDataSM;
        myRole = "Sub Master";
        eType = "SM";
    } else if (mRole.includes("subcompany")) {
        if (activeUser)
            sideBarData = sidebarDataSC;
        myRole = "Sub Company";
        eType = "SC";
    } else if (mRole.includes("superstockist")) {
        if (activeUser)
            sideBarData = sidebarDataSST;
        myRole = "Super Stockist";
        eType = "SST";
    } else if (mRole.includes("stockist")) {
        if (activeUser)
            sideBarData = sidebarDataStockist;
        myRole = "Stockist";
        eType = "Stockist";
    }
    console.log("MiniNavBar sidebardata", sideBarData);

    const handleToggle = (index) => {
        if (dropDown === index) {
            setDropDown(null);
        } else {
            setDropDown(index);
        }
    };

    const fetchBalance = () => {
        api
            .get(`${"beta/getBalance"}`)
            .then(res => {
                console.log("fetch balance", res);
                if (res) {
                    setBalance(res.data);
                    user.balance = res.data;
                    TokenService.updateBalance(res.data);
                }
            })
            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    useEffect(() => {
        if (user && user.accountStatus.includes("ACTIVE")) {
            setActiveUser(true);
            fetchBalance();

            // Set up a timer to fetch the balance every 5 seconds
            const intervalId = setInterval(() => {
                fetchBalance();
            }, 1000);

            // Clean up the interval when the component unmounts
            return () => clearInterval(intervalId);
        } else {
            const newUser = TokenService.getUser();
            if (newUser) {
                setBalance(newUser.balance);
            }
        }
    }, [user]);

    let getBannerMessage = "alpha/getGlobalProperty/agentBannerMessage";
    const [bannerMessage, setBannerMessage] = useState();

    const fetchBannerMessage = () => {
        api
            .get(`${getBannerMessage}`)
            .then(res => {
                console.log("get banner message res", res);
                if (res) {
                    setBannerMessage(res.data);
                } else {
                    setBannerMessage(res);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    useEffect(() => {
        if (activeUser) {
            fetchBannerMessage();
        } else {
            setBannerMessage("Welcome to C247!");
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" color="transparent" style={{ paddingTop: "0px", background: '#f3f3f4', paddingRight: "0px" }} open={open}>
                <Toolbar
                // style={{  paddingLeft: "0px", marginLeft: "0px" }}
                >
                    {!open ? <div className="app-logo-humburger-icon-container">
                        {/* <img src={App_Icon} className="app-logo"></img> */}
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none', paddingLeft: 0, marginRight: 0 }),
                                // background: 'purple',
                                // paddingLeft: 10,
                                '&:hover': {
                                    // bgcolor: 'transparent',
                                    // color: "white"
                                },
                            }}
                        >
                            <MenuIcon />
                        </IconButton></div>
                        : <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}
                                sx={{
                                    marginRight: 5,
                                    // paddingLeft: 10,
                                    ...(open && { paddingLeft: 0, marginRight: 0 }),
                                    '&:hover': {
                                        // bgcolor: 'transparent',
                                        // color: "white"
                                    },
                                }}
                            >
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </DrawerHeader>}
                    <div style={{ width: "100%", textAlign: "right" }}>
                        <label style={{ fontSize: "11px", marginRight: "-15px" }}>
                            Bal:
                        </label>
                        <span style={{ fontSize: "11px", fontWeight: "bold" }}>
                            {balance}
                        </span>
                        <Button style={{ color: "#3963af", fontSize: "11px" }} onClick={() => logout()}><FaSignOutAlt style={{ color: "#3963af", marginRight: "5px", height: "12px" }} />Sign out</Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={{
                '& .MuiDrawer-paper': {
                    boxShadow: 'none',  // Remove the shadow
                    borderRight: 'none', // If there's a border, remove it
                    background: "#1b2227",
                },
            }}>
                <List sx={{ pt: 0.1, background: "#1b2227", height: "100%" }}>
                    <div className="mini-nav-bar-profile-block">
                        {user && <div>
                            <ListItemText primary={user.userId + `(${user.firstName})`} primaryTypographyProps={{ fontSize: '12px' }} />
                            <ListItemText primary={"Bal: " + user.balance} primaryTypographyProps={{ fontSize: '12px' }} />
                            <ListItemText sx={{ color: "steelblue" }} primary={eType} primaryTypographyProps={{ fontSize: '12px' }} />
                        </div>}
                    </div>
                    {/* {isSmallScreen && <div className="mini-nav-bar-profile-block">
                        {user && <div>
                            <ListItemText primary={user.userId} primaryTypographyProps={{ fontSize: '12px' }} />
                            <ListItemText primary={`(${user.firstName})`} primaryTypographyProps={{ fontSize: '12px' }} />
                            <ListItemText sx={{ color: "steelblue" }} primary={eType} primaryTypographyProps={{ fontSize: '12px' }} />
                        </div>}
                    </div>} */}
                    {activeUser && sideBarData.map((item, index) => (
                        <div key={index}>
                            {item.subItems ? (
                                <>
                                    <List disablePadding>
                                        <ListItem
                                            ref={(el) => (listItemRefs.current[index] = el)}
                                            // onClick={() => handleSubMenuToggle(index, item)}
                                            onClick={() => handleMenuToggle(index, item)}
                                            style={{ position: 'relative', width: '100%' }}
                                            key={index}
                                            disablePadding
                                            sx={{
                                                display: 'block',
                                                pt: "4px",
                                                bgcolor: selectedIndex === index ? '#171a1d' : 'transparent',
                                                color: selectedIndex === index ? 'white' : customDrawerStyles.listItem,
                                                '&:hover': {
                                                    bgcolor: '#171a1d',
                                                    color: "white"
                                                },
                                            }}
                                        >
                                            <ListItemButton>
                                                <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText sx={customDrawerStyles.listItemText} primary={item.label} primaryTypographyProps={{ fontSize: '14px' }} />
                                                {activeSubMenu === index ? <FaChevronUp sx={customDrawerStyles.listItemIcon} /> : <FaChevronDown sx={customDrawerStyles.listItemIcon} />}
                                            </ListItemButton>
                                        </ListItem>
                                        {item.subItems && activeSubMenu === index &&
                                            <Collapse
                                                in={activeSubMenu}
                                                timeout={1000} // Duration of the transition in milliseconds
                                                easing="ease-in-out" // Optional easing function
                                                unmountOnExit
                                            >
                                                <List component="div" disablePadding className="mini-nav-bar-submenu-div" >
                                                    {item.subItems.map((subItem, subIndex) => (
                                                        <ListItem
                                                            key={subIndex}
                                                            component={Link}
                                                            to={subItem.link}
                                                            disablePadding
                                                            sx={{
                                                                display: 'block',
                                                                pt: "4px",
                                                                bgcolor: selectedSubIndex === subIndex ? '#171a1d' : 'transparent',
                                                                color: selectedSubIndex === subIndex ? 'white' : customDrawerStyles.listItem,
                                                                '&:hover': {
                                                                    bgcolor: '#171a1d',
                                                                    color: "white"
                                                                },
                                                                pl: "20px",
                                                            }}
                                                            style={{ textDecoration: "none" }}
                                                            onClick={(e) => handleSubMenuToggle(e, subIndex, subItem)}
                                                        // onClick={() => setOpenDrawer(false)}
                                                        >
                                                            <ListItemButton>
                                                                <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                                                                    {subItem.icon}
                                                                </ListItemIcon>
                                                                <ListItemText sx={customDrawerStyles.listItemText} primary={subItem.label} primaryTypographyProps={{ fontSize: '12px' }} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Collapse>}
                                    </List>
                                    {/* <ListItem
                                        onClick={() => handleToggle(index)}
                                        style={{ position: 'relative', width: '100%' }}
                                    >
                                        <ListItemButton>
                                            <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.label} />
                                            {dropDown === index ? (
                                                <ExpandLessIcon /> // Display the 'expand less' icon when open
                                            ) : (
                                                <ExpandMoreIcon /> // Display the 'expand more' icon when closed
                                            )}
                                        </ListItemButton>
                                    </ListItem>
                                    <Collapse in={dropDown === index} timeout="auto" unmountOnExit>
                                        <List>
                                            {item.subItems.map((subItem, subIndex) => (
                                                <ListItem
                                                    key={subIndex}
                                                    component={Link}
                                                    to={subItem.link}
                                                    sx={customDrawerStyles.sublListItem}
                                                // onClick={() => setOpenDrawer(false)}
                                                >
                                                    <ListItemButton>
                                                        <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                                                            {subItem.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={subItem.label} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse> */}
                                </>
                            ) : (
                                <ListItem
                                    key={index}
                                    component={Link} // Use Link as the component for routing
                                    to={item.link} // Set the link
                                    onClick={() => handleMenuToggle(index)}
                                    disablePadding
                                    sx={{
                                        display: 'block',
                                        pt: "4px",
                                        bgcolor: selectedIndex === index ? '#171a1d' : 'transparent',
                                        color: selectedIndex === index ? 'white' : customDrawerStyles.listItem,
                                        '&:hover': {
                                            bgcolor: '#171a1d',
                                            color: "white"
                                        },
                                    }}
                                    style={{ textDecoration: "none" }}
                                // onClick={() => setOpenDrawer(false)}

                                >
                                    <ListItemButton>
                                        {item.label === "Search Mole" ? <ListItemIcon sx={customDrawerStyles.listItemIconRed}>
                                            {item.icon}
                                        </ListItemIcon> : <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                                            {item.icon}
                                        </ListItemIcon>}
                                        <ListItemText sx={customDrawerStyles.listItemText} primary={item.label} primaryTypographyProps={{ fontSize: '14px' }} />
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </div>
                    ))}
                </List>
            </Drawer>
            {/* {subMenuItem && <SubMenuContainer
                style={{
                    top: subMenuPosition.top,
                    left: subMenuPosition.left,
                    background: "#2d333a",
                    transform: 'translateX(100%)',
                }}
                onMouseDown={stopPropagation}
                onTouchStart={stopPropagation}
            >
                <SubMenuList component="div" disablePadding>
                    {subMenuItem.subItems.map((subItem, subIndex) => (
                        <ListItem
                            key={subIndex}
                            component={Link}
                            to={subItem.link}
                            disablePadding
                            sx={{
                                display: 'block',
                                pt: "4px",
                                bgcolor: selectedSubIndex === subIndex ? '#171a1d' : 'transparent',
                                color: selectedSubIndex === subIndex ? 'white' : customDrawerStyles.listItem,
                                '&:hover': {
                                    bgcolor: '#171a1d',
                                    color: "white"
                                },
                            }}
                            style={{ textDecoration: "none" }}
                            onClick={() => handleSubMenuToggle(subIndex)}
                        // onClick={() => setOpenDrawer(false)}
                        >
                            <ListItemButton>
                                <ListItemIcon sx={customDrawerStyles.listItemIcon}>
                                    {subItem.icon}
                                </ListItemIcon>
                                <ListItemText sx={customDrawerStyles.listItemText} primary={subItem.label} primaryTypographyProps={{ fontSize: '12px' }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </SubMenuList>
            </SubMenuContainer>} */}
            <Box component="main" sx={{ flexGrow: 1, paddingTop: 0, height: "100%" }}>
                <DrawerHeader />
                <div className="top-div">
                    <div style={{ marginBottom: isSmallScreen ? "8px" : "0px" }}>
                        {/* <NavbarNew /> */}
                        {/* {role && <Navbar role={role} logout={logout} user={currentUser} />} */}
                        {role && visitedPaths && <MarqeeNavPath visitedPaths={visitedPaths} bannerMessage={bannerMessage} />}
                        {/* <div>
                        <Marqee />
                        <Breadcrumbs />
                    </div> */}
                    </div>
                    <div className="active-user-content-footer-holder">
                        <div className="active-user-content-holder">
                            <Routes>
                                {/* <Route path="/login" exact element={role ? <Home role={role} logout={logout} /> : <Login role={role} logout={logout} />} />
            <Route path="/" exact element={role ? <Home role={role} logout={logout} /> : <Login role={role} logout={logout} />} /> */}
                                <Route path="/dashboard"
                                    element={<Home role={role} logout={logout} />} />
                                <Route path="/home"
                                    element={<CricHome role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/edit"
                                    element={<Edit role={role} logout={logout} />} />
                                {/* <Route path="/childrenssc1"
            element={<ImmediateChildrens role={role} logout={logout} />} />
          <Route path="/childrenssst1"
            element={<ImmediateChildrens1 role={role} logout={logout} />} />
          <Route path="/childrensst1"
            element={<ImmediateChildrens2 role={role} logout={logout} />} />
          <Route path="/childrensagent1"
            element={<ImmediateChildrens3 role={role} logout={logout} />} />
          <Route path="/showsc1"
            element={<MasterDownlineTableLite role={role} logout={logout} />} />
          <Route path="/showsst1"
            element={<MasterDownlineTableLite role={role} logout={logout} />} />
          <Route path="/showst1"
            element={<MasterDownlineTableLite role={role} logout={logout} />} />
          <Route path="/showagent1"
            element={<MasterDownlineTableLite role={role} logout={logout} />} />
          <Route path="/showuser1"
            element={<MasterDownlineTableLite role={role} logout={logout} />} /> */}
                                <Route path="/registersm"
                                    element={<CricRegister role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/registersc"
                                    element={<CricRegister role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/registersst"
                                    element={<CricRegister role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/registerst"
                                    element={<CricRegister role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/registeragent"
                                    element={<CricRegister role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/registeruser"
                                    element={<CricRegister role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/profile"
                                    element={<Profile role={role} logout={logout} />} />
                                <Route path="/changepassword"
                                    element={<CricChangePassword role={role} logout={logout} />} />
                                <Route path="/changemypassword"
                                    element={<CricChangeMyPassword role={role} logout={logout} />} />
                                <Route path="/changeuserpassword"
                                    element={<ChangeUserPassword role={role} logout={logout} />} />
                                <Route path="/userpassword"
                                    element={<CricChangeUserPassword role={role} logout={logout} />} />
                                <Route path="/deposit"
                                    element={<Deposit role={role} logout={logout} />} />
                                <Route path="/withdraw"
                                    element={<Withdraw role={role} logout={logout} />} />
                                <Route path="/statement"
                                    element={<CricStatement open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/ledgers"
                                    element={<Ledgers open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/livematches"
                                    element={<MatchesLiveTable role={role} logout={logout} />} />
                                <Route path="/upcomingmatches1"
                                    element={<MatchesUpcomingTable role={role} logout={logout} />} />
                                <Route path="/pastmatches1"
                                    element={<MatchesPastTable role={role} logout={logout} />} />
                                <Route path="/registergames"
                                    element={<RegisterGames role={role} logout={logout} />} />
                                <Route path="/startgame"
                                    element={<GameStart role={role} logout={logout} />} />
                                <Route path="/gamescreen"
                                    element={<GameScreen role={role} logout={logout} />} />
                                <Route path="/gamescreen"
                                    element={<GameScreen2 role={role} logout={logout} />} />
                                <Route path="/matchcomplete"
                                    element={<MatchComplete role={role} logout={logout} />} />
                                {/* <Route path="/bethistory1"
            element={<BetHistory role={role} logout={logout} />} />
          <Route path="/masterbook1"
            element={<MasterBook role={role} logout={logout} />} />
          <Route path="/mbsc1"
            element={<MasterBook1 role={role} logout={logout} />} />
          <Route path="/mbsst1"
            element={<MasterBook2 role={role} logout={logout} />} />
          <Route path="/mbst1"
            element={<MasterBook3 role={role} logout={logout} />} />
          <Route path="/mbagent1"
            element={<MasterBook4 role={role} logout={logout} />} />
          <Route path="/muipagination"
            element={<MUIPagination role={role} logout={logout} />} /> */}
                                <Route path="/bethistory"
                                    element={<MUIPaginationBetHistory role={role} logout={logout} />} />
                                {/* <Route path="/showsc"
                            element={<MUIPaginationMasterDownline open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                        <Route path="/showsst"
                            element={<MUIPaginationMasterDownline open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                        <Route path="/showst"
                            element={<MUIPaginationMasterDownline open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                        <Route path="/showagent"
                            element={<MUIPaginationMasterDownline open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                        <Route path="/showuser"
                            element={<MUIPaginationMasterDownline open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} /> */}
                                <Route path="/showsm"
                                    element={<MyDownlines open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/showsc"
                                    element={<MyDownlines open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/showsst"
                                    element={<MyDownlines open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/showst"
                                    element={<MyDownlines open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/showagent"
                                    element={<MyDownlines open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/showuser"
                                    element={<MyDownlines open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                {/* <Route path="/showsc"
                            element={<TableCustomized open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                        <Route path="/showsst"
                            element={<MyDownline open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                        <Route path="/showst"
                            element={<MyDownline open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                        <Route path="/showagent"
                            element={<MyDownline open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                        <Route path="/showuser"
                            element={<MyDownline open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} /> */}
                                <Route path="/masterbook"
                                    element={<MUIPMasterBook role={role} logout={logout} />} />
                                <Route path="/mbsc"
                                    element={<MUIPMasterBook1 role={role} logout={logout} />} />
                                <Route path="/mbsst"
                                    element={<MUIPMasterBook2 role={role} logout={logout} />} />
                                <Route path="/mbst"
                                    element={<MUIPMasterBook3 role={role} logout={logout} />} />
                                <Route path="/mbagent"
                                    element={<MUIPMasterBook4 role={role} logout={logout} />} />
                                {/* <Route path="/childrenssc"
                            element={<MUIPImmediateChildren role={role} logout={logout} />} />
                        <Route path="/childrenssst"
                            element={<MUIPImmediateChildren1 role={role} logout={logout} />} />
                        <Route path="/childrensst"
                            element={<MUIPImmediateChildren2 role={role} logout={logout} />} />
                        <Route path="/childrensagent"
                            element={<MUIPImmediateChildren3 role={role} logout={logout} />} /> */}
                                <Route path="/ic/:userId"
                                    element={<ImmediateChildrens role={role} logout={logout} />} />
                                {/* <Route path="/childrenssc"
                            element={<ImmediateChildrens role={role} logout={logout} />} /> */}
                                {/* <Route path="/childrenssst"
                            element={<ImmediateChildrens1 role={role} logout={logout} />} />
                        <Route path="/childrensst"
                            element={<ImmediateChildrens2 role={role} logout={logout} />} />
                        <Route path="/childrensagent"
                            element={<ImmediateChildrens3 role={role} logout={logout} />} /> */}
                                <Route path="/upcomingmatches"
                                    element={<MUIPUpcomingMatches role={role} logout={logout} supportedSports={supportedSports} />} />
                                <Route path="/pastmatches"
                                    element={<MUIPPastMatches role={role} logout={logout} supportedSports={supportedSports} />} />
                                <Route path="/eventexposure"
                                    element={<MUIPPastEventExposure role={role} logout={logout} />} />
                                <Route path="/eesc"
                                    element={<MUIPPastEventExposure1 role={role} logout={logout} />} />
                                <Route path="/eesst"
                                    element={<MUIPPastEventExposure2 role={role} logout={logout} />} />
                                <Route path="/eest"
                                    element={<MUIPPastEventExposure3 role={role} logout={logout} />} />
                                <Route path="/eeagent"
                                    element={<MUIPPastEventExposure4 role={role} logout={logout} />} />
                                <Route path="/sports/:event"
                                    element={<SupportedSports role={role} logout={logout} supportedSports={supportedSports} />} />
                                <Route path="/matchscreen/:sport/:id/:title"
                                    element={<MatchScreenCricket role={role} logout={logout} open={open} isSmallScreen={isSmallScreen} />} />
                                <Route path="/matchlist"
                                    element={<LiveMatchesTable role={role} logout={logout} />} />
                                <Route path="/matcheslist"
                                    element={<LiveMatches role={role} logout={logout} supportedSports={supportedSports} isSmallScreen={isSmallScreen} />} />
                                <Route path="/compmatcheslist"
                                    element={<LiveMatches role={role} logout={logout} supportedSports={supportedSports} isSmallScreen={isSmallScreen} />} />
                                <Route path="/matchesdashboard/:sport/:id/:title"
                                    element={<AgentMatchDashboard role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/livematchscreen/:sport/:id/:title"
                                    element={<LiveMatchScreen role={role} logout={logout} />} />
                                <Route path="/icdashboard/:uid/:etype"
                                    element={<AgentDashboard role={role} logout={logout} open={open} isSmallScreen={isSmallScreen} />} />
                                <Route path="/receivecash/:uid"
                                    element={<ReceiveCash role={role} logout={logout} />} />
                                <Route path="/paycash/:uid"
                                    element={<PayCash role={role} logout={logout} />} />
                                <Route path="/update"
                                    element={<Update role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/collectionreport"
                                    element={<CollectionReport role={role} logout={logout} supportedSports={supportedSports} isSmallScreen={isSmallScreen} />} />
                                <Route path="/eledger/:uid/:etype"
                                    element={<EntityLedger role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/settings"
                                    element={<AdminSettings role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/updateprop"
                                    element={<UpdateGlobalProperties role={role} logout={logout} />} />
                                <Route path="/clients"
                                    element={<MyClients open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/blockedclients"
                                    element={<MyClients open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/commissionlimits"
                                    element={<CommisionAndLimits open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/estatement"
                                    element={<EntityStatement role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/directagents/:uid/:etype"
                                    element={<DirectAgents open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/directclients/:uid/:etype"
                                    element={<DirectAgents open={open} isSmallScreen={isSmallScreen} role={role} logout={logout} />} />
                                <Route path="/blockmarket"
                                    element={<BlockMarket role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/betslipmatch/:sport/:id/:title"
                                    element={<BetSlipMatch role={role} logout={logout} open={open} isSmallScreen={isSmallScreen} />} />
                                <Route path="/betslipbookmaker/:sport/:id/:title"
                                    element={<BetSlipMatch role={role} logout={logout} open={open} isSmallScreen={isSmallScreen} />} />
                                <Route path="/betslipsession/:sport/:id/:title"
                                    element={<BetSlipSession role={role} logout={logout} open={open} isSmallScreen={isSmallScreen} />} />
                                <Route path="/searchmole"
                                    element={<SearchMole role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/myledgers"
                                    element={<CompanyLenDen role={role} logout={logout} isSmallScreen={isSmallScreen} />} />
                                <Route path="/collectionreportmatch/:sport/:id/:title"
                                    element={<CollectionReportMatch role={role} logout={logout} supportedSports={supportedSports} isSmallScreen={isSmallScreen} />} />
                                <Route path="/profitandloss"
                                    element={<ProfitAndLoss role={role} logout={logout} supportedSports={supportedSports} isSmallScreen={isSmallScreen} />} />
                                <Route path="/clientreport/:sport/:id/:title"
                                    element={<ClientReport role={role} logout={logout} supportedSports={supportedSports} isSmallScreen={isSmallScreen} />} />
                                <Route path="/sessionearningreport/:sport/:id/:title"
                                    element={<SessionEarningReport role={role} logout={logout} supportedSports={supportedSports} isSmallScreen={isSmallScreen} />} />
                                <Route path="/companyreport/:sport/:id/:title"
                                    element={<CompanyReport role={role} logout={logout} supportedSports={supportedSports} isSmallScreen={isSmallScreen} />} />
                            </Routes>
                        </div>
                        <div className="cric-footer-container">
                            {role && <CricFooter role={role} />}
                        </div>
                    </div>
                </div>
            </Box>
        </Box>
    );
}
