// src/DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/DynamicTable.css';
import '../css/MatchesTable.css';
import '../css/AgentTablePagination.css';
import TokenService from '../../../services/token-service';
import { summaryTable } from './Columns';

const SummaryTable = () => {

    const user = TokenService.getUser();
    return (
        <div className="matches-table-root">
            <table className="summary-table-custom">
                <thead>
                    <tr>
                        {summaryTable.map((column, index) => (
                            <th className="summary-table-header-cell-grey" key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr className="summary-table-row">
                        <td className="summary-table-custom-td">
                            {user && user.balance}
                        </td>
                        <td className="summary-table-custom-td">
                            {user && user.downLineBalance && user.downLineBalance.toFixed(2)}
                        </td>
                        <td className="summary-table-custom-td">
                            {user && user.exposure && user.exposure.toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default SummaryTable;