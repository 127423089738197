// src/DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DEPOSIT_COINS, WITHDRAW_COINS } from '../common/Constants';
import '../css/ProfitAndLossTable.css';

const ProfitAndLossTable = ({ columns, data, tableHeader, setAllTimeTotal }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(1000);

    // useEffect(() => {
    //     const handleResize = () => {
    //         if (window.innerWidth <= 600) {
    //             setItemsPerPage(5);
    //         } else {
    //             setItemsPerPage(5);
    //         }
    //     };

    //     handleResize(); // Initial check
    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentData = data.slice(startIndex, startIndex + itemsPerPage);

    // Generate pagination buttons
    const pageNumbers = [];
    const visiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(1, endPage - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // let navigate = useNavigate();
    // const openLiveReport = (e, id, title) => {
    //     e.preventDefault();
    //     navigate(`/matchscreen/${sport}/${id}/${title}`);
    //     // navigate(`/livematchscreen/${sport}/${id}/${title}`);
    // };

    const [totalEarnings, setTotalEarnings] = useState(0);
    const [matchEarnings, setMatchEarnings] = useState(0);
    const [commissionEarnings, setCommissionEarnings] = useState(0);

    useEffect(() => {
        let totalEarnings = 0;
        let matchEarnings = 0;
        let commissionEarnings = 0;

        currentData.forEach(record => {
            console.log("currentData ", record);

            totalEarnings = totalEarnings + record.totalEarnings;
            matchEarnings = matchEarnings + record.matchEarnings;
            commissionEarnings = commissionEarnings + record.commissionEarnings;
        });

        setTotalEarnings(totalEarnings);
        setAllTimeTotal(totalEarnings);
        setMatchEarnings(matchEarnings);
        setCommissionEarnings(commissionEarnings);
    }, [data]);

    return (
        <div className="profit-loss-table-root">
            {/* <div className="profit-loss-table-container"> */}
            <table className="profit-loss-table-custom">
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th className="profit-loss-table-header-cell-grey" key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((row, rowIndex) => (
                        <tr key={rowIndex} className="profit-loss-table-row">
                            <td className="profit-loss-table-custom-td">
                                {row.date}
                            </td>
                            <td className="profit-loss-table-custom-td">
                                {row.matchId}
                            </td>
                            <td className="profit-loss-table-custom-td">
                                <Link className="td-market-sport-link" style={{ textDecoration: "none" }}>
                                    {row.matchName}
                                </Link>
                            </td>
                            <td className="profit-loss-table-custom-td">
                                {row.matchEarnings.toFixed(2)}
                            </td>
                            <td className="profit-loss-table-custom-td">
                                {row.commissionEarnings.toFixed(2)}
                            </td>
                            <td className="profit-loss-table-custom-td">
                                {row.totalEarnings.toFixed(2)}
                            </td>
                        </tr>
                    ))}
                    <tr className="profit-loss-table-row-total">
                        <td className="profit-loss-table-custom-td-total" colSpan={3}>
                            All Page Total
                        </td>
                        <td className="profit-loss-table-custom-td-earnings">
                            {matchEarnings.toFixed(2)}
                        </td>
                        <td className="profit-loss-table-custom-td-earnings">
                            {commissionEarnings.toFixed(2)}
                        </td>
                        <td className="profit-loss-table-custom-td-earnings">
                            {totalEarnings.toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* </div> */}
            {/* {currentData && <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="agent-table-pagination-prev-button"
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {startPage > 1 && <span className="pagination-ellipsis">...</span>}
                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`profit-loss-table-pagination-button ${currentPage === page ? 'active' : ''}`}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="agent-table-pagination-next-button"
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>} */}
        </div>
    );
};

export default ProfitAndLossTable;
