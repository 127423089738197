import React from 'react';
import '../css/BetSlip.css';
import BetSlipMarketPositionRunners from './BetSlipMarketPositionRunners';

const BetSlipMarketPosition2 = ({ headerTitle, detailContent, isSmallScreen, extraLeftMargin, marketPosition, candidateMarketsName, candidateMarketsId }) => {
    return (
        <div>
            <div className="header-market-position-container">
                <div className="header">
                    Market Position
                </div>
                <div className='market-separator-below-header'></div>
            </div>
            <div className="market-position-runner-position-container">
                <BetSlipMarketPositionRunners headerTitle={"RUNNER"} runnerPositionOne={candidateMarketsName[0]} runnerPositionTwo={candidateMarketsName[1]} />
                <BetSlipMarketPositionRunners headerTitle={"POSITION"} runnerPositionOne={marketPosition[candidateMarketsId[0]].toFixed(2)} runnerPositionTwo={marketPosition[candidateMarketsId[1]].toFixed(2)} />
            </div>
        </div>
    );
}

export default BetSlipMarketPosition2;
