import "../parvati/style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { useNavigate, useLocation } from 'react-router-dom';
import TransactionArrow from '../../assets/arrow_crop_20.png';
// import DatePickerCalender from './DatePickerCalender';
import DateRangePresetsPicker from "./DateRangePresetsPicker";
import DateRangePicker from "./DateRangePicker";
import TableHeader from "./TableHeader";
import { agentLedger } from "./tables/Columns";
import EntityLedgerTable from "./tables/EntityLedgerTable";
import TableTitle from "./customized/TableTitle";
import CompanyLenDenTable from "./tables/CompanyLenDenTable";

export default function CompanyLenDen({ role, logout, isSmallScreen }) {

    // const { state } = useLocation();

    // if (state === null) {
    //     logout();
    // }
    // const { child } = state ? state : "";
    // console.log("cash ledger", child);
    // const userId = child ? child.userId : "";

    let getCompanyLenDen = "beta/companyLenDen?offset=0&limit=1000";
    const api = httpHelpers();
    const [ledgers, setLedgers] = useState([]);
    let navigate = useNavigate();

    const fetchCompanyLenDen = (startDate, endDate) => {
        if (!startDate || !endDate) {
            startDate = "";
            endDate = "";
        }
        api
            .get(`${getCompanyLenDen}` + "&startDate=" + startDate + "&endDate=" + endDate)
            .then(res => {
                console.log("ledgers res", res);
                if (res && res.data && res.data.length > 0) {
                    setLedgers(res.data);
                } else {
                    setLedgers([]);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    // useEffect(() => {
    //     fetchStatement();
    // }, []);

    return (
        <div className="entity-ledger-root">
            <div className="date-range-presets-picker-container">
                {/* <DatePickerCalender fetchStatement={fetchStatement} /> */}
                {/* <DateRangePresetsPicker /> */}
                <DateRangePicker fetchStatement={fetchCompanyLenDen} isSmallScreen={isSmallScreen} fetchLast7DaysOnLoad={true} />
            </div>
            <div className="entity-ledger-root">
                {/* <TableHeader title={"Agent Ledger"} minWidth={"100px"} /> */}
                <TableTitle
                    text="Agent Ledger"
                    color="#ffffff"
                    fontSize="14px"
                    textAlign="left"
                    width={isSmallScreen ? "98.2%" : "100%"}
                    height="46px"
                    marginLeft="0px"
                    paddingLeft="10px"
                />
                <CompanyLenDenTable columns={agentLedger} data={ledgers} tableHeader={"Agent Ledger"} />
            </div>
        </div>
    );
};
