import "../parvati/style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { useNavigate, useLocation } from 'react-router-dom';
import TransactionArrow from '../../assets/arrow_crop_20.png';
// import DatePickerCalender from './DatePickerCalender';
import DateRangePresetsPicker from "./DateRangePresetsPicker";
import DateRangePicker from "./DateRangePicker";
import TableHeader from "./TableHeader";
import { profitAndLoss } from "./tables/Columns";
import EntityLedgerTable from "./tables/EntityLedgerTable";
import StatementTable from "./tables/StatementTable";
import TokenService from "../../services/token-service";
import TableTitle from "./customized/TableTitle";
import EntityStatementTable from "./tables/EntityStatementTable";
import ProfitAndLossTable from "./tables/ProfitAndLossTable";
import SummaryTable from "./tables/SummaryTable";
import ProfitAndLossSummary from "./tables/ProfitAndLossSummary";

export default function ProfitAndLoss({ role, logout, isSmallScreen }) {

    let user = TokenService.getUser();
    if (!user) {
        logout();
    }

    let userId = null;
    const { state } = useLocation();
    console.log("profitloss", state);

    if (state) {
        const { child } = state ? state : null;
        console.log("profitloss", child);
        userId = child.userId;
    }

    let getProfitAndLoss = "beta/profitLoss?offset=0&limit=1000";
    if (userId) {
        getProfitAndLoss = "beta/profitLoss?userId=" + userId + "&offset=0&limit=1000";
    } else {
        userId = user.userId;
    }
    const api = httpHelpers();
    const [profitLoss, setProfitLoss] = useState([]);
    const [allTimeTotal, setAllTimeTotal] = useState(0);

    const fetchProfitAndLoss = (startDate, endDate) => {
        if (!startDate || !endDate) {
            startDate = "";
            endDate = "";
        }
        api
            .get(`${getProfitAndLoss}` + "&startDate=" + startDate + "&endDate=" + endDate)
            .then(res => {
                console.log("profit loss res", res);
                if (res && res.data && res.data.length > 0) {
                    setProfitLoss(res.data);
                } else {
                    setProfitLoss([]);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    return (
        <div>
            <div className="date-range-presets-picker-container">
                {/* <DatePickerCalender fetchStatement={fetchStatement} /> */}
                {/* <DateRangePresetsPicker /> */}
                <DateRangePicker fetchStatement={fetchProfitAndLoss} isSmallScreen={isSmallScreen} fetchLast7DaysOnLoad={true} />
            </div>
            <div className="entity-ledger-root">
                <div style={{ paddingBottom: "0px", marginBottom: "20px" }}>
                    <TableTitle
                        text="Summary"
                        color="#ffffff"
                        fontSize="14px"
                        textAlign="left"
                        width={isSmallScreen ? "98.5%" : "100%"}
                        height="36px"
                        marginLeft="0px"
                        paddingLeft="10px"
                    />
                    <ProfitAndLossSummary allTimeTotal={allTimeTotal} />
                </div>
                {/* <TableHeader title={"Statement of " + userId} minWidth={"100px"} /> */}
                <TableTitle
                    text={"Earning Report " + userId}
                    color="#ffffff"
                    fontSize="14px"
                    textAlign="left"
                    width={isSmallScreen ? "98.5%" : "100%"}
                    height="46px"
                    marginLeft="0px"
                    paddingLeft="10px"
                />
                {/* <StatementTable columns={statementTable} data={coinLedger} tableHeader={"Statement of " + userId} /> */}
                <ProfitAndLossTable columns={profitAndLoss} data={profitLoss} setAllTimeTotal={setAllTimeTotal} />
            </div>
        </div>
    );
};
