import React, { useState } from 'react';
import { Button, Select, MenuItem, Typography, Container } from '@mui/material';
import '../css/AgentTablePagination.css';

const Pagination = ({ totalEntries, rowsPerPageOptions, page, setPage, rowsPerPage, setRowsPerPage }) => {
    const [currentPage, setCurrentPage] = useState(page);
    // const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const totalPages = Math.ceil(totalEntries / rowsPerPage);

    const handlePageChange = (page) => {
        if (page >= 0 && page <= totalPages - 1) {
            setPage(page);
            setCurrentPage(page);
        }
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when rows per page changes
    };

    return (
        <div className="agent-table-pagination-container">
            {/* Left Section: Rows Per Page & Entries Information */}
            <div className="agent-table-pagination-info">
                {/* <div className="rows-per-page">
                    <label style={{ marginRight: "7px" }}>
                        Rows per page:
                    </label>
                    <Select
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        variant="outlined"
                        size="small"
                        className="select"
                    >
                        {rowsPerPageOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </div> */}
                <label className="agent-table-showing-entries">
                    Showing {(currentPage) * rowsPerPage + 1} to{" "}
                    {Math.min((currentPage + 1) * rowsPerPage, totalEntries)} of {totalEntries} entries
                </label>
            </div>

            {/* Right Section: Pagination Controls */}
            <div className="agent-table-pagination-controls">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 0}
                    className="agent-table-pagination-prev-button"
                >
                    Previous
                </button>
                <label className="agent-table-page-number">
                    {currentPage + 1}
                </label>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages - 1}
                    className="agent-table-pagination-next-button"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default Pagination;
