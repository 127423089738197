import "rsuite/dist/rsuite.min.css";
import { useNavigate, useParams } from 'react-router-dom';
import TableHeader from "./TableHeader";
import './css/LiveMatches.css';
import TableTitle from "./customized/TableTitle";

export default function AgentMatchDashboard({ role, logout, isSmallScreen }) {
    console.log("show", role);
    const { sport, id, title } = useParams();
    let navigate = useNavigate();

    const openBetSlipMatch = (e) => {
        e.preventDefault();
        navigate(`/betslipmatch/${sport}/${id}/${title}`);
    };

    const openBetSlipBookmaker = (e) => {
        e.preventDefault();
        navigate(`/betslipbookmaker/${sport}/${id}/${title}`);
    };

    const openBetSlipSession = (e) => {
        e.preventDefault();
        navigate(`/betslipsession/${sport}/${id}/${title}`);
    };

    const openLiveReport = (e) => {
        e.preventDefault();
        navigate(`/matchscreen/${sport}/${id}/${title}`);
        // navigate(`/livematchscreen/${sport}/${id}/${title}`);
    };

    const openClientReport = (e) => {
        e.preventDefault();
        navigate(`/clientreport/${sport}/${id}/${title}`);
    };

    const openCollectionReport = (e) => {
        e.preventDefault();
        navigate(`/collectionreportmatch/${sport}/${id}/${title}`);
    };

    const openCompanyReport = (e) => {
        e.preventDefault();
        navigate(`/companyreport/${sport}/${id}/${title}`);
    };

    const openSessionEarningReport = (e) => {
        e.preventDefault();
        navigate(`/sessionearningreport/${sport}/${id}/${title}`);
    };

    return (
        <div>
            <div>
                {/* <TableHeader title={"Agent Match Dashboard"} /> */}
                <TableTitle
                    text="Agent Match Dashboard"
                    color="#ffffff"
                    fontSize="24px"
                    textAlign="left"
                    width={isSmallScreen ? "97%" : "99.2%"}
                    height="60px"
                />
            </div>
            <div style={{ paddingTop: "20px", paddingBottom: "20px", background: "white" }}>
                <div className="cric-agent-matches-dashboard-container">
                    <div className="cric-agent-matches-dashboard-buttons-container">
                        <div className="cric-agent-matches-dashboard-buttons-parent">
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openBetSlipMatch(e)}>
                                Match Bet Slip
                            </button>
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openBetSlipMatch(e)}>
                                Bookmaker Bet Slip
                            </button>
                        </div>
                        <div className="cric-agent-matches-dashboard-buttons-parent">
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openBetSlipSession(e)}>
                                Session Bet Slip
                            </button>
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openLiveReport(e)}>
                                Live Report
                            </button>
                        </div>
                    </div>
                </div>
                <div className="cric-agent-matches-dashboard-container">
                    <div className="cric-agent-matches-dashboard-buttons-container">
                        <div className="cric-agent-matches-dashboard-buttons-parent">
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openClientReport(e)}>
                                Client Report
                            </button>
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openCollectionReport(e)}>
                                Collection Report
                            </button>
                        </div>
                        <div className="cric-agent-matches-dashboard-buttons-parent">
                            {/* {childType === "user" && <button
                                style={{ marginLeft: "5px", background: "transparent", width: isSmallScreen ? "55%" : "51%" }} >
                            </button>} */}
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openCompanyReport(e)}>
                                Company Report
                            </button>
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openSessionEarningReport(e)}>
                                Session Earning Report
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
