import { useState, useEffect } from "react";
import { httpHelpers } from "../../../services/httpHelpers";
import { marketTable } from "../tables/Columns";
import MarketTable from "../tables/MarketTable";
import TableTitle from "../customized/TableTitle";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../../actions/message";
import { SET_MESSAGE } from "../../../actions/types";
import { MARKET_STATUS_BLOCKED, MARKET_STATUS_UNBLOCKED } from "../common/Constants";
import TokenService from "../../../services/token-service";
import '../css/BetSlip.css';
import HeaderDetailComponent from "./BetSlipBetHolder";
import BetSlipWinnerTab from "./BetSlipWinnerTab";
import CustomizedDropDownMenu from "../customized/CustomizedDropDownMenu";
import { MATCH_ODDS, BOOKMAKER, FANCY_BET } from "../common/Constants";
import BetSlipMarketPosition from "./BetSlipMarketPosition";
import BetSlipStyledPaginationTable from "./BetSlipStylePaginationTable";
import BetSlipStyledPaginationTable2 from "./BetSlipStylePaginationTable2";
import BetSlipStyledPaginationTable3 from "./BetSlipStylePaginationTable3";
import BetSlipMarketPosition2 from "./BetSlipMarketPosition2";
import BetSlipMarketPosition3 from "./BetSlipMarketPosition3";
import BetSlipMarketPosition4 from "./BetSlipMarketPosition4";
import BetSlipStyledPaginationTableSession from "./BetSlipStylePaginationTableSession";
import CustomizedDropDownMenuSession from "../customized/CustomizedDropDownMenuSession";

export default function BetSlipSession({ role, logout, child, open, isSmallScreen }) {

    const href = window.location.href;
    let slipType = FANCY_BET;
    let marketType = "session";
    if (href.includes("betslipmatch")) {
        slipType = MATCH_ODDS;
        marketType = "matchodds";
    } else if (href.includes("betslipbookmaker")) {
        slipType = BOOKMAKER;
        marketType = "bookmaker";
    }

    const [betSlipType, setBetSlipType] = useState(slipType);
    const user = TokenService.getUser();
    let userId = user.userId;

    if (child)
        userId = child.userId;
    const { sport, id, title } = useParams();
    const [successMessage, setSuccessMessage] = useState();
    const [successful, setSuccessful] = useState(false);
    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    //let getBetSlip = "beta/getBetSlip?matchId=" + id + "&betType=" + marketType + "&offset=0&limit=20";
    const [userName, setUserName] = useState("alluser");
    const [marketName, setMarketName] = useState("allfancy");
    let getBetSlip = "beta/getSessionBetSlip?matchId=" + id;
    const api = httpHelpers();
    const [betSlips, setBetSlips] = useState([]);
    const [totalBets, setTotalBets] = useState();
    const [setteledBets, setSetteledBets] = useState();
    const [unsetteledBets, setUnsetteledBets] = useState();
    const [revertedBets, setRevertedBets] = useState();
    const [users, setUsers] = useState();
    // const [betTypes, setBetTypes] = useState();
    // const [marketPosition, setMarketPosition] = useState();
    const [betSlipEntries, setBetSlipEntries] = useState();
    const [sessions, setSessions] = useState();
    const [candidateMarketsId, setCandidateMarketsId] = useState();
    const [candidateMarketsName, setCandidateMarketsName] = useState();

    const fetchBetSlip = () => {
        console.log("get bet slip url", getBetSlip);
        api
            .get(`${getBetSlip}`)
            .then(res => {
                console.log("get bet slip res", res);
                if (res && res.data) {
                    setTotalBets(res.data.totalBets);
                    setSetteledBets(res.data.settledBets);
                    setUnsetteledBets(res.data.unsettledBets);
                    setRevertedBets(res.data.revertedBets);
                    // setBetTypes(res.data.betTypes);
                    // setMarketPosition(res.data.marketPosition);
                    setBetSlipEntries(res.data.betSlipEntries);
                    if (!users)
                        setUsers(res.data.users);
                    if (!candidateMarketsId)
                        setCandidateMarketsId(Object.keys(res.data.sessions));
                    if (!candidateMarketsName)
                        setCandidateMarketsName(Object.values(res.data.sessions));
                    setSessions(res.data.sessions);
                    setBetSlips(res.data);
                } else {
                    setBetSlips([]);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    // useEffect(() => {
    //     fetchBetSlip();
    // }, []);

    useEffect(() => {
        if (userName != "alluser" && marketName != "allfancy") {
            getBetSlip = "beta/getSessionBetSlip?matchId=" + id + "&userId=" + userName + "&sessionId=" + marketName;
        } else if (userName != "alluser" && marketName === "allfancy") {
            getBetSlip = "beta/getSessionBetSlip?matchId=" + id + "&userId=" + userName;
        } else if (userName === "alluser" && marketName != "allfancy") {
            getBetSlip = "beta/getSessionBetSlip?matchId=" + id + "&sessionId=" + marketName;
        } else {
            getBetSlip = "beta/getSessionBetSlip?matchId=" + id;
        }

        fetchBetSlip();
    }, [userName, marketName]);

    // useEffect(() => {
    //     dispatch(clearMessage());
    //     fetchMarkets();
    // }, []);

    // useEffect(() => {
    //     fetchMarkets();
    // }, [successful]);

    return (
        <div className="bet-slip-root">
            <div className="bet-slip-holder-container">
                <HeaderDetailComponent
                    headerTitle="Total Bets"
                    detailContent={totalBets}
                />
                <HeaderDetailComponent
                    headerTitle="Settled Bets"
                    detailContent={setteledBets}
                />
                <HeaderDetailComponent
                    headerTitle="Unsettled Bets"
                    detailContent={unsetteledBets}
                />
                <HeaderDetailComponent
                    headerTitle="Reverted Bets"
                    detailContent={revertedBets}
                />
            </div>
            {users && sessions && <div className="bet-slip-betsman-dropdown">
                <div className="bet-slip-users-dropdown">
                    <CustomizedDropDownMenuSession users={users} userName={userName} setUserName={setUserName} />
                </div>
                {(betSlipType === MATCH_ODDS || betSlipType === FANCY_BET) && <div className="bet-slip-winner-toss-dropdown">
                    <CustomizedDropDownMenuSession sessions={sessions} candidateMarketsId={candidateMarketsId} candidateMarketsName={candidateMarketsName} marketName={marketName} setMarketName={setMarketName} />
                </div>}
            </div>}
            {betSlipType === BOOKMAKER && <div className="bet-slip-tab-container">
                <BetSlipWinnerTab open={open} isSmallScreen={isSmallScreen} extraLeftMargin={true} />
            </div>}
            {/* {marketPosition && candidateMarketsName && betSlipType === MATCH_ODDS && <div className="bet-slip-market-position-container" style={{ marginLeft: "5px" }}>
                {candidateMarketsName.length == 2 && <BetSlipMarketPosition2 isSmallScreen={isSmallScreen} extraLeftMargin={true} marketPosition={marketPosition} candidateMarketsName={candidateMarketsName} candidateMarketsId={candidateMarketsId} />}
                {candidateMarketsName.length == 3 && <BetSlipMarketPosition3 isSmallScreen={isSmallScreen} extraLeftMargin={true} marketPosition={marketPosition} candidateMarketsName={candidateMarketsName} candidateMarketsId={candidateMarketsId} />}
                {candidateMarketsName.length == 4 && <BetSlipMarketPosition4 isSmallScreen={isSmallScreen} extraLeftMargin={true} marketPosition={marketPosition} candidateMarketsName={candidateMarketsName} candidateMarketsId={candidateMarketsId} />}
            </div>} */}
            {betSlipEntries && candidateMarketsName && (betSlipType === MATCH_ODDS || betSlipType === FANCY_BET) && <div>
                <div className="header-bet-slip-table-container">
                    <div className="header">
                        Bet Slips
                    </div>
                    <div className='market-separator-below-header'></div>
                </div>
                {/* <BetSlipTable open={open} isSmallScreen={isSmallScreen} /> */}
                {/* <BetSlipStyledPaginationTable open={open} isSmallScreen={isSmallScreen} /> */}
                <BetSlipStyledPaginationTableSession open={open} isSmallScreen={isSmallScreen} betSlipEntries={betSlipEntries} />
                {/* {candidateMarketsName.length == 2 && <BetSlipStyledPaginationTable2 open={open} isSmallScreen={isSmallScreen} candidateMarketsName={candidateMarketsName} candidateMarketsId={candidateMarketsId} betSlipEntries={betSlipEntries} />}
                {candidateMarketsName.length == 3 && <BetSlipStyledPaginationTable3 open={open} isSmallScreen={isSmallScreen} candidateMarketsName={candidateMarketsName} candidateMarketsId={candidateMarketsId} betSlipEntries={betSlipEntries} />}
                {candidateMarketsName.length == 4 && <BetSlipStyledPaginationTable3 open={open} isSmallScreen={isSmallScreen} candidateMarketsName={candidateMarketsName} candidateMarketsId={candidateMarketsId} betSlipEntries={betSlipEntries} />} */}
            </div>}
        </div>
    );
};
