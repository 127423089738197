import "../parvati/style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import TransactionArrow from '../../assets/arrow_crop_20.png';
// import DatePickerCalender from './DatePickerCalender';
import DateRangePresetsPicker from "./DateRangePresetsPicker";
import DateRangePicker from "./DateRangePicker";
import TableHeader from "./TableHeader";
import { companyReport } from "./tables/Columns";
import EntityLedgerTable from "./tables/EntityLedgerTable";
import StatementTable from "./tables/StatementTable";
import TokenService from "../../services/token-service";
import TableTitle from "./customized/TableTitle";
import EntityStatementTable from "./tables/EntityStatementTable";
import ProfitAndLossTable from "./tables/ProfitAndLossTable";
import SummaryTable from "./tables/SummaryTable";
import ProfitAndLossSummary from "./tables/ProfitAndLossSummary";
import ClientReportTable from "./tables/ClientReportTable";
import CompanyReportTable from "./tables/CompanyReportTable";

export default function CompanyReport({ role, logout, isSmallScreen }) {

    let user = TokenService.getUser();
    if (!user) {
        logout();
    }

    const { sport, id, title } = useParams();
    let getCompanyReport = "beta/companyReport?matchId=" + id;
    const api = httpHelpers();
    const [companyMatchReport, setCompanyMatchReport] = useState([]);

    const fetchCompanyReport = () => {
        api
            .get(`${getCompanyReport}`)
            .then(res => {
                console.log("company report res", res);
                if (res && res.data) {
                    setCompanyMatchReport(res.data.companyReportEntries);
                } else {
                    setCompanyMatchReport([]);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    useEffect(() => {
        fetchCompanyReport();
    }, []);

    return (
        <div>
            <div className="entity-ledger-root">
                {/* <TableHeader title={"Statement of " + userId} minWidth={"100px"} /> */}
                <TableTitle
                    text={"Match Report"}
                    color="#ffffff"
                    fontSize="14px"
                    textAlign="left"
                    width={isSmallScreen ? "98.5%" : "100%"}
                    height="46px"
                    marginLeft="0px"
                    paddingLeft="10px"
                />
                {/* <StatementTable columns={statementTable} data={coinLedger} tableHeader={"Statement of " + userId} /> */}
                {companyReport && <CompanyReportTable columns={companyReport} data={companyMatchReport} />}
            </div>
        </div>
    );
};
