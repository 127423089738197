import React from 'react';
import '../css/BetSlip.css';

const BetSlipMarketPositionRunners = ({ headerTitle, runnerPositionOne, runnerPositionTwo, runnerPositionThree, runnerPositionFour }) => {
    return (
        <div className="bet-slip-market-position-runner-container">
            <div className="header" style={{ fontSize: "13px" }}>
                {headerTitle}
            </div>
            <div className='market-separator-below-detail'></div>
            <div className="market-position-detail">
                {runnerPositionOne}
            </div>
            <div className='market-separator-below-detail'></div>
            <div className="market-position-detail">
                {runnerPositionTwo}
            </div>
            {runnerPositionThree && <div>
                <div className='market-separator-below-detail'></div>
                <div className="market-position-detail">
                    {runnerPositionThree}
                </div></div>}
            {runnerPositionFour && <div>
                <div className='market-separator-below-detail'></div>
                <div className="market-position-detail">
                    {runnerPositionFour}
                </div></div>}
        </div>
    );
}

export default BetSlipMarketPositionRunners;
