import {
  MdDashboard, MdManageAccounts,
  MdPassword, MdPages, MdSettings,
  MdBlock,
  MdPercent,
  MdSportsCricket,
  MdMoney,
  MdCalculate,
  MdBalance,
  MdBusiness,
  MdCasino,
} from "react-icons/md";

export const sidebarDataAgent = [
  { label: 'Dashboard', icon: <MdDashboard />, link: '/home' },
  { label: 'Live Matches', icon: <MdSportsCricket />, link: 'matcheslist' },
  { label: 'Completed Matches', icon: <MdSportsCricket />, link: 'compmatcheslist' },
  { label: 'Live Casino', icon: <MdCasino /> },
  { label: 'Virtual Casino', icon: <MdCasino /> },
  {
    label: 'Manage Clients',
    icon: <MdManageAccounts />,
    link: '/manageclients',
    subItems: [
      { label: 'My Clients', icon: <MdManageAccounts />, link: '/clients' },
      { label: 'Blocked Clients', icon: <MdBlock />, link: '/blockedclients' },
      { label: 'Commission & Limits', icon: <MdPercent />, link: '/commissionlimits' },
    ],
  },
  {
    label: 'Manage Ledgers',
    icon: <MdPages />,
    link: '/manage',
    subItems: [
      { label: 'Collection Report', icon: <MdCalculate />, link: '/collectionreport' },
      { label: 'Company Len-Den', icon: <MdBusiness />, link: '/myledgers' },
      { label: 'In-Out', icon: <MdBalance />, link: '/estatement' },
      { label: 'P & L', icon: <MdMoney />, link: '/profitandloss' },
    ],
  },
  { label: 'Change Password', icon: <MdPassword />, link: '/changemypassword' },
];