import "../parvati/style/MasterDownlineTableLite.css";
import { useState, useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import TransactionArrow from '../../assets/arrow_crop_20.png';
// import DatePickerCalender from './DatePickerCalender';
import DateRangePresetsPicker from "./DateRangePresetsPicker";
import DateRangePicker from "./DateRangePicker";
import TableHeader from "./TableHeader";
import { sessionEarningReport } from "./tables/Columns";
import EntityLedgerTable from "./tables/EntityLedgerTable";
import StatementTable from "./tables/StatementTable";
import TokenService from "../../services/token-service";
import TableTitle from "./customized/TableTitle";
import EntityStatementTable from "./tables/EntityStatementTable";
import ProfitAndLossTable from "./tables/ProfitAndLossTable";
import SummaryTable from "./tables/SummaryTable";
import ProfitAndLossSummary from "./tables/ProfitAndLossSummary";
import ClientReportTable from "./tables/ClientReportTable";
import SessionEarningReportTable from "./tables/SessionEarningReportTable";

export default function SessionEarningReport({ role, logout, isSmallScreen }) {

    let user = TokenService.getUser();
    if (!user) {
        logout();
    }

    const { sport, id, title } = useParams();
    let getSessionEarningReport = "beta/sessionEarningReport?matchId=" + id;
    const api = httpHelpers();
    const [immediateSubMaster, setImmediateSubMaster] = useState(null);
    const [immediateSubCompany, setImmediateSubCompany] = useState(null);
    const [immediateSuperStockist, setImmediateSuperStockist] = useState(null);
    const [immediateStockist, setImmediateStockist] = useState(null);
    const [immediateAgent, setImmediateAgent] = useState(null);
    const [immediateUser, setImmediateUser] = useState(null);

    const fetchSessionEarningReport = () => {
        api
            .get(`${getSessionEarningReport}`)
            .then(res => {
                console.log("session earning report res", res);
                if (res && res.data && res.data.sessionEarningReports) {
                    if (res.data.sessionEarningReports.submaster)
                        setImmediateSubMaster(res.data.sessionEarningReports.submaster);
                    if (res.data.sessionEarningReports.subcompany)
                        setImmediateSubCompany(res.data.sessionEarningReports.subcompany);
                    if (res.data.sessionEarningReports.superstockist)
                        setImmediateSuperStockist(res.data.sessionEarningReports.superstockist);
                    if (res.data.sessionEarningReports.stockist)
                        setImmediateStockist(res.data.sessionEarningReports.stockist);
                    if (res.data.sessionEarningReports.agent)
                        setImmediateAgent(res.data.sessionEarningReports.agent);
                    if (res.data.sessionEarningReports.user)
                        setImmediateUser(res.data.sessionEarningReports.user);
                } else {
                    setImmediateSubMaster(null);
                    setImmediateSubCompany(null);
                    setImmediateSuperStockist(null);
                    setImmediateStockist(null);
                    setImmediateAgent(null);
                    setImmediateUser(null);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    useEffect(() => {
        fetchSessionEarningReport();
    }, []);

    return (
        <div>
            <div className="entity-ledger-root">
                {/* <TableHeader title={"Statement of " + userId} minWidth={"100px"} /> */}
                {immediateSubMaster && <div>
                    <TableTitle
                        text={"Sub Master"}
                        color="#ffffff"
                        fontSize="14px"
                        textAlign="left"
                        width={isSmallScreen ? "98.5%" : "100%"}
                        height="46px"
                        marginLeft="0px"
                        paddingLeft="10px"
                    />
                    {/* <StatementTable columns={statementTable} data={coinLedger} tableHeader={"Statement of " + userId} /> */}
                    <SessionEarningReportTable columns={sessionEarningReport} data={immediateSubMaster} />
                </div>}
                {immediateSubCompany && <div>
                    <TableTitle
                        text={"Sub Company"}
                        color="#ffffff"
                        fontSize="14px"
                        textAlign="left"
                        width={isSmallScreen ? "98.5%" : "100%"}
                        height="46px"
                        marginLeft="0px"
                        paddingLeft="10px"
                    />
                    {/* <StatementTable columns={statementTable} data={coinLedger} tableHeader={"Statement of " + userId} /> */}
                    <SessionEarningReportTable columns={sessionEarningReport} data={immediateSubCompany} />
                </div>}
                {immediateSuperStockist && <div>
                    <TableTitle
                        text={"SST"}
                        color="#ffffff"
                        fontSize="14px"
                        textAlign="left"
                        width={isSmallScreen ? "98.5%" : "100%"}
                        height="46px"
                        marginLeft="0px"
                        paddingLeft="10px"
                    />
                    {/* <StatementTable columns={statementTable} data={coinLedger} tableHeader={"Statement of " + userId} /> */}
                    <SessionEarningReportTable columns={sessionEarningReport} data={immediateSuperStockist} />
                </div>}
                {immediateStockist && <div>
                    <TableTitle
                        text={"Stockists"}
                        color="#ffffff"
                        fontSize="14px"
                        textAlign="left"
                        width={isSmallScreen ? "98.5%" : "100%"}
                        height="46px"
                        marginLeft="0px"
                        paddingLeft="10px"
                    />
                    {/* <StatementTable columns={statementTable} data={coinLedger} tableHeader={"Statement of " + userId} /> */}
                    <SessionEarningReportTable columns={sessionEarningReport} data={immediateStockist} />
                </div>}
                {immediateAgent && <div>
                    <TableTitle
                        text={"Agent"}
                        color="#ffffff"
                        fontSize="14px"
                        textAlign="left"
                        width={isSmallScreen ? "98.5%" : "100%"}
                        height="46px"
                        marginLeft="0px"
                        paddingLeft="10px"
                    />
                    {/* <StatementTable columns={statementTable} data={coinLedger} tableHeader={"Statement of " + userId} /> */}
                    <SessionEarningReportTable columns={sessionEarningReport} data={immediateAgent} />
                </div>}
                {immediateUser && <div>
                    <TableTitle
                        text={"Clients"}
                        color="#ffffff"
                        fontSize="14px"
                        textAlign="left"
                        width={isSmallScreen ? "98.5%" : "100%"}
                        height="46px"
                        marginLeft="0px"
                        paddingLeft="10px"
                    />
                    {/* <StatementTable columns={statementTable} data={coinLedger} tableHeader={"Statement of " + userId} /> */}
                    <SessionEarningReportTable columns={sessionEarningReport} data={immediateUser} />
                </div>}
            </div>
        </div>
    );
};
